<template>
  <SettingsCard
    :title="$t('settings.features.phone.title')"
    :subtitle="$t('settings.features.phone.subtitle')"
    icon="mdi-phone-check"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.features.phone.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.features.phone.body-texts.1') }}
            </span><br><br>
            <span class="grey--text">
              {{ $t('settings.features.phone.body-texts.2') }}<br>
              {{ $t('settings.features.phone.body-texts.3') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-radio-group
            v-model="isPhoneOptional"
            :label="$t('settings.features.phone.labels.info')"
          >
            <v-radio
              color="primary"
              class="mb-1 pa-2 suggestion"
              :value="true"
            >
              <template v-slot:label>
                <div class="flex-column d-flex">
                  <span>
                    {{ $t('settings.features.phone.labels.optional') }}
                  </span>
                </div>
              </template>
            </v-radio>
            <v-radio
              color="primary"
              class="mb-1 pa-2 suggestion"
              :value="false"
            >
              <template v-slot:label>
                <div class="flex-column d-flex">
                  <span>
                    {{ $t('settings.features.phone.labels.required') }}
                  </span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <ChangeSettingsAlert
        :alert-text="$t('alerts.settings.features.phone.info')"
      />
    </div>
  </SettingsCard>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import ChangeSettingsAlert from '@/components/ChangeSettingsAlert.vue'
import UPDATE_PHONE_OPTIONAL from './queries/UpdateIsPhoneOptional.gql'
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    SettingsCard,
    ChangeSettingsAlert
  },
  mixins: [featureMixin],
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    refetchSettings: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isPhoneOptional: this.settings.company.isPhoneOptional
    }
  },
  watch: {
    isPhoneOptional () {
      this.saveSettings()
    }
  },
  methods: {
    async saveSettings () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PHONE_OPTIONAL,
          variables: {
            input: { isPhoneOptional: this.isPhoneOptional, companyId: this.$auth.user.companyId }
          }
        })
        this.refetchSettings()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.features.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      }
    }
  }
}
</script>
