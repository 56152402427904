<template>
  <div>
    <div class="d-flex justify-space-between flex-md-row flex-column">
      <PackageLogo :current-package="currentPackage" />
      <Permission permission="subscription:change">
        <Feature :feature-slug="featureNames.CHANGE_SUBSCRIPTION">
          <v-btn
            v-if="!isExpert"
            color="primary"
            :class="{'w-full mt-4':$vuetify.breakpoint.smAndDown}"
            :disabled="disabled"
            @click="changeSubscription"
          >
            <v-icon>mdi-swap-horizontal</v-icon>
            {{ $t('buttons.package.cta') }}
          </v-btn>
        </Feature>
      </Permission>
    </div>

    <ChangeSubscription
      :dialog="isUpgradeDialogOpen"
      :current-package="currentPackage"
      :company="company"
      :hide-downgrade="true"
      @close="isUpgradeDialogOpen = false"
    />
  </div>
</template>

<script>
import { ProductType } from '@/lib/productTypes'
import ChangeSubscription from '../ChangeSubscription'
import PackageLogo from '@/components/PackageLogo'
import featureMixin from '@/mixins/feature'
import Permission from '@/components/Permission'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    ChangeSubscription,
    PackageLogo,
    Permission
  },
  mixins: [featureMixin, brandingMixin],
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isUpgradeDialogOpen: false
    }
  },
  computed: {
    isExpert () {
      return this.company?.productType === ProductType.EXPERT
    },
    currentPackage () {
      return this.company?.productType
    }
  },
  methods: {
    changeSubscription () {
      if (this.isPlatformMarkero) {
        this.$router.push({ name: 'product-packages' })
        return
      }

      this.isUpgradeDialogOpen = true
    }
  }

}
</script>
