<template>
  <SettingsCard
    :title="$t('settings.features.navigation.title')"
    :subtitle="$t('settings.features.navigation.subtitle')"
    icon="mdi-menu"
    :is-default-open="false"
  >
    <div class="px-4 pt-4">
      <v-row
        v-for="(category,index) in categories"
        :key="index"
      >
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ category.title }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.features.navigation.body-text', { title: category.title }) }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-expansion-panels flat>
            <v-expansion-panel class="expansion-panel-border">
              <v-expansion-panel-header>
                <span>
                  {{ $t('settings.features.navigation.list', { title: category.title }) }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item,i) in category.items"
                  :key="i"
                  class="py-1"
                  @click="showUpsellingDialog(item)"
                >
                  <v-switch
                    v-if="!item.disabled"
                    v-model="activePages"
                    :value="item.slug"
                    :label="`${item.name}`"
                    dense
                    :disabled="item.disabled"
                    :loading="isLoadingSlugs[item.slug]"
                    @click.native.capture="changeStatus($event, item.slug)"
                  />
                  <div
                    v-else
                    class="rounded-lg upselling-container"
                  >
                    <GradientBackground
                      :top-left="gradientColors.start"
                      :top-right="gradientColors.end"
                      :bottom-left="gradientColors.start"
                      :bottom-right="gradientColors.end"
                      style="opacity: 0.9"
                    />
                    <div
                      class="pa-2 overlay"
                    >
                      <span class="white--text font-weight-bold">{{ item.name }}</span>
                      <div class="justify-center py-2 d-flex">
                        <v-btn
                          small
                          color="primary"
                        >
                          {{ $t('buttons.upselling.cta') }}
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
    <ChangeSettingsAlert />
    <v-dialog
      v-model="isUpsellingDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('settings.features.navigation.activate', { title }) }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="isUpsellingDialogVisible=false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-5">
          <p>
            {{ $t('settings.features.navigation.info-texts.0') }} {{ content }}: <b>{{ selectedItem?.name }}</b> {{ $t('settings.features.navigation.info-texts.1') }}.
            {{ $t('settings.features.navigation.info-texts.2') }}:
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="routeToUpselling()"
          >
            {{ $t('settings.features.navigation.info-texts.3') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </SettingsCard>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import SettingsCard from '@/components/SettingsCard.vue'
import ChangeSettingsAlert from '@/components/ChangeSettingsAlert.vue'
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import GradientBackground from '@/components/GradientBackground.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: { SettingsCard, ChangeSettingsAlert, GradientBackground },
  mixins: [currencyMixin, featureMixin, brandingMixin],
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    guides: {
      type: Array,
      default: () => ([])
    },
    checklists: {
      type: Array,
      default: () => ([])
    },
    tools: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      loading: false,
      selectedItem: null,
      isUpsellingDialogVisible: false,
      activePages: this.getActivePages(),
      isLoadingSlugs: {},
      loaderTimeout: null,
      categories: {
        tools: {
          title: this.$t('landingpages.labels.tools'),
          items: this.tools
        },
        guides: {
          title: this.$t('landingpages.labels.guides'),
          items: this.guides
        },
        checklists: {
          title: this.$t('landingpages.labels.checklists'),
          items: this.checklists
        }
      }
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    },
    route () {
      return {
        guide: 'guides',
        checklist: 'checklists'
      }[this.selectedItem?.type.toLowerCase()] || 'tools'
    },
    title () {
      return {
        guides: this.$t('settings.features.navigation.title-texts.0'),
        checklists: this.$t('settings.features.navigation.title-texts.1'),
        tools: this.$t('settings.features.navigation.title-texts.2')
      }[this.route]
    },
    content () {
      return {
        guides: this.$t('settings.features.navigation.content-texts.0'),
        checklists: this.$t('settings.features.navigation.content-texts.1'),
        tools: this.$t('settings.features.navigation.content-texts.2')
      }[this.route]
    }
  },
  unmounted () {
    clearTimeout(this.loaderTimeout)
  },
  created () {
    this.$watch(vm => [vm.tools, vm.guides, vm.checklists].join(), lps => {
      this.activePages = this.getActivePages()
      this.categories.tools.items = this.tools
      this.categories.checklists.items = this.checklists
      this.categories.guides.items = this.guides
    })
    bus.$on(eventNames.CHANGE_FEATURE_CONFIG, (event) => {
      if (event.slug !== featureNames.LANDINGPAGE_NAVIGATION) return
      if (event.error) bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      this.activePages = this.getActivePages()
    })
  },
  methods: {
    getActivePages () {
      return [...this.tools, ...this.guides, ...this.checklists]
        .map(({ slug }) => slug)
        .filter(slug => this.$features._featuresBySlug['landingpage-navigation'].config[slug])
    },
    showUpsellingDialog (tool) {
      if (!tool.disabled) return
      this.isUpsellingDialogVisible = true
      this.selectedItem = tool
    },
    routeToUpselling () {
      this.$router.push('/landingpages/#' + this.route)
    },
    finishLoading (milliseconds) {
      setTimeout(() => { this.loading = false }, milliseconds)
    },
    changeStatus (event, slug) {
      this.$set(this.isLoadingSlugs, slug, true)
      this.loaderTimeout = setTimeout(() => {
        this.saveSettings(slug)
      }, 2000)
    },
    async saveSettings (changedSlug) {
      await this.$features.setCompanyFeature(
        {
          companyId: this.$auth.user.companyId,
          slug: featureNames.LANDINGPAGE_NAVIGATION,
          config: [...this.tools, ...this.guides, ...this.checklists].reduce((obj, lp) => {
            obj[lp.slug] = this.activePages.includes(lp.slug)
            return obj
          }, {})
        }
      )
      this.$set(this.isLoadingSlugs, changedSlug, false)
    }
  }
}
</script>
<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.overlay{
  z-index: 2;
  position: relative;
}

.switch {
  min-height: 40px;
}
.upselling-container{
  position:relative;
  cursor: pointer;
  max-width: 400px;
  overflow:hidden;
}
.background-image{
  position: absolute;
  bottom: 0;
  left: 10px;
  height: 30px;
}
</style>
