<template>
  <SettingsCard
    :title="$t('settings.email.domain-settings.title')"
    :subtitle="$t('settings.email.domain-settings.subtitle')"
    icon="mdi-email-seal"
    :is-default-open="false"
  >
    <div
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.email.domain-settings.body-texts.0') }}
              </span>
            </div>
            <div class="grey--text mb-2">
              {{ $t('settings.email.domain-settings.body-texts.1') }}
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column px-0 px-md-2"
        >
          <div class="mr-12 d-flex align-center justify-space-between">
            <v-text-field
              class="font-weight-bold"
              label="Domain"
              hide-details
              readonly
              outlined
              auto-grow
              no-resize
              rows="1"
              :value="domain.domain"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="hoppermation.hasDKIMVerification"
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.email.domain-settings.dkim-texts.0') }}
              </span>
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">{{ $t('settings.email.domain-settings.dkim-texts.1') }}<br></span>
              {{ $t('settings.email.domain-settings.dkim-texts.2') }}
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">{{ $t('settings.email.domain-settings.dkim-texts.3') }}<br></span>
              {{ $t('settings.email.domain-settings.dkim-texts.4') }}
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column px-0 px-md-2"
        >
          <div class="mb-4 d-flex align-center justify-space-between">
            <div
              v-if="domain.isDKIMVerified"
              class="font-weight-bold"
            >
              <v-icon
                color="success"
                class="pr-4"
              >
                mdi-check-decagram
              </v-icon>
              <span>{{ $t('settings.email.domain-settings.labels.verified') }}</span>
            </div>

            <div
              v-else
              class="font-weight-bold"
            >
              <v-icon
                color="error"
                class="pr-4"
              >
                mdi-alert-decagram
              </v-icon>
              <span>{{ $t('settings.email.domain-settings.labels.not-verified') }}</span>
            </div>
            <v-btn
              v-if="!domain.isDKIMVerified"
              outlined
              color="primary"
              class="px-2 option-btn"
              :loading="isLoading"
              :disabled="isLoading"
              @click="verifyDKIM"
            >
              {{ $t('buttons.verify.cta') }}
            </v-btn>
          </div>
          <div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                :label="$t('settings.email.domain-settings.labels.hostname')"
                hide-details
                readonly
                outlined
                auto-grow
                no-resize
                rows="1"
                :value="domain.dkimHost"
              />

              <CopyToClipboardButton :value="domain.dkimHost" />
            </div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                :label="$t('settings.email.domain-settings.labels.txt-entry')"
                hide-details
                readonly
                outlined
                auto-grow
                no-resize
                :value="domain.dkimText"
              />
              <CopyToClipboardButton :value="domain.dkimText" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="hoppermation.hasReturnPathVerification"
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.email.domain-settings.return-path-texts.0') }}
              </span>
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">{{ $t('settings.email.domain-settings.return-path-texts.1') }}<br></span>
              {{ $t('settings.email.domain-settings.return-path-texts.2') }}
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">{{ $t('settings.email.domain-settings.return-path-texts.3') }}<br></span>
              {{ $t('settings.email.domain-settings.return-path-texts.3') }}
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column px-0 px-md-2"
        >
          <div class="mb-4 d-flex align-center justify-space-between">
            <div
              v-if="domain.isReturnPathDomainVerified"
              class="font-weight-bold"
            >
              <v-icon
                color="success"
                class="pr-4"
              >
                mdi-check-decagram
              </v-icon>
              <span>{{ $t('settings.email.domain-settings.labels.verified') }}</span>
            </div>

            <div
              v-else
              class="font-weight-bold"
            >
              <v-icon
                color="error"
                class="pr-4"
              >
                mdi-alert-decagram
              </v-icon>
              <span>{{ $t('settings.email.domain-settings.labels.not-verified') }}</span>
            </div>
            <v-btn
              v-if="!domain.isReturnPathDomainVerified"
              outlined
              color="primary"
              class="px-2 option-btn"
              :disabled="isLoading"
              :loading="isLoading"
              @click="verifyReturnPath"
            >
              {{ $t('buttons.verify.cta') }}
            </v-btn>
          </div>
          <div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                :label="$t('settings.email.domain-settings.labels.hostname')"
                hide-details
                readonly
                outlined
                auto-grow
                no-resize
                rows="1"
                :value="domain.returnPathDomain"
              />
              <CopyToClipboardButton :value="domain.returnPathDomain" />
            </div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                :label="$t('settings.email.domain-settings.labels.cname-entry')"
                hide-details
                readonly
                outlined
                :rows="1"
                auto-grow
                no-resize
                :value="domain.returnPathDomainCname"
              />
              <CopyToClipboardButton :value="domain.returnPathDomainCname" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-alert
      v-if="hoppermation.hasReturnPathVerification || hoppermation.hasDKIMVerification"
      icon="mdi-lightbulb-on-20"
      prominent
      text
      type="info"
      class="ma-0 mb-8"
    >
      <p class="ma-0 font-weight-bold">
        {{ $t('settings.email.domain-settings.howto-texts.0') }}
      </p>
      <ol>
        <li>
          {{ $t('settings.email.domain-settings.howto-texts.1') }}<br>
          <a
            href="https://postmarkapp.com/support/article/1090-resources-for-adding-dkim-and-return-path-records-to-dns-for-common-hosts-and-dns-providers"
            target="_blank"
            rel="noopener"
          >{{ $t('settings.email.domain-settings.howto-texts.2') }}</a>
        </li>
        <li>
          {{ $t('settings.email.domain-settings.howto-texts.3') }}
        </li>
      </ol>
    </v-alert>
    <VerifyDomainDialog
      :dialog="verifyDialog"
      @close="verifyDialog = false"
    />
  </SettingsCard>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue'
import GET_DOMAIN from '@/queries/GetDomain.gql'
import VERIFY_DOMAIN from './queries/VerifyDomain.gql'
import bus, { eventNames } from '@/lib/eventBus'
import VerifyDomainDialog from './VerifyDomainDialog.vue'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import { clipboard } from '@/lib/clipboard'

export default {
  components: { SettingsCard, VerifyDomainDialog, CopyToClipboardButton },
  mixins: [featureMixin],
  data () {
    return {
      isLoading: false,
      verifyDialog: false,
      domain: {}
    }
  },
  computed: {
    hoppermation () {
      const {
        config: {
          hasDKIMVerification,
          hasReturnPathVerification
        } = { config: {} }
      } = this.getFeature(featureNames.HOPPERMATION)
      return {
        hasDKIMVerification,
        hasReturnPathVerification
      }
    }
  },
  methods: {
    async verifyDKIM () {
      await this.verifyDomain()
      if (!this.domain.isDKIMVerified) {
        this.verifyDialog = true
      }
    },
    async verifyReturnPath () {
      await this.verifyDomain()
      if (!this.domain?.isReturnPathDomainVerified) {
        this.verifyDialog = true
      }
    },
    async copyToClipboard  (value) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.domain-settings.success-copy') })

      return await clipboard(value)
    },
    async verifyDomain () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: VERIFY_DOMAIN,
          variables: {
            companyId: this.$auth.user.companyId
          },
          update: (store, { data: { domain } }) => {
            store.writeQuery({ query: GET_DOMAIN, data: { domain }, variables: { companyId: this.$auth.user.companyId } })
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.domain-settings.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.domain-settings.error') })
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    domain: {
      query: GET_DOMAIN,
      variables () {
        return { companyId: this.$auth.user.companyId }
      }
    }
  }
}
</script>
