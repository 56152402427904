<template>
  <SettingsCard
    :title="$t('settings.features.contact.title')"
    :subtitle="$t('settings.features.contact.subtitle')"
    icon="mdi-cog"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.features.contact.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.features.contact.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="px-0 px-md-2"
        >
          <TextForm
            :fields="contactFields"
            @update="updateContact"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.features.contact.body-texts.2') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.features.contact.body-texts.3') }}
            </span>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="px-0 px-md-2"
        >
          <PhoneField
            v-model="phone"
            update-on-input
            @input="(val) => updateContact({'phone': val})"
          />
          <PhoneField
            v-model="mobile"
            :label="$t('settings.features.contact.labels.mobile')"
            update-on-input
            @input="(val) => updateContact({'mobile': val})"
          />
          <div class="py-6">
            <TextForm
              :fields="emailField"
              @update="updateContact"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import UPDATE_COMPANY_AMBASSADOR_NAME from './queries/UpdateCompanyAmbassadorName.gql'
import UPDATE_COMPANY_AMBASSADOR_ROLE from './queries/UpdateCompanyAmbassadorRole.gql'
import UPDATE_COMPANY_AMBASSADOR_PHONE from './queries/UpdateCompanyAmbassadorPhone.gql'
import UPDATE_COMPANY_AMBASSADOR_MOBILE from './queries/UpdateCompanyAmbassadorMobile.gql'
import UPDATE_COMPANY_AMBASSADOR_EMAIL from './queries/UpdateCompanyAmbassadorEmail.gql'

import TextForm from '@/components/TextForm.vue'
import PhoneField from '@/components/PhoneField.vue'

import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    SettingsCard,
    TextForm,
    PhoneField
  },
  mixins: [featureMixin],
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    refetchCompany: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      phone: this.company.ambassador.phone,
      mobile: this.company.ambassador.mobile
    }
  },
  computed: {
    contactFields () {
      return [{
        label: this.$t('settings.features.contact.labels.name'),
        name: 'name',
        placeholder: '',
        required: false,
        value: this.company.ambassador.name
      },
      {
        label: this.$t('settings.features.contact.labels.role'),
        name: 'role',
        placeholder: '',
        required: false,
        value: this.company.ambassador.role
      }]
    },
    emailField () {
      return [{
        label: this.$t('settings.features.contact.labels.email'),
        name: 'email',
        placeholder: '',
        required: false,
        value: this.company.ambassador.email
      }]
    }
  },
  methods: {
    async updateContact (values) {
      const mutations = {
        name: UPDATE_COMPANY_AMBASSADOR_NAME,
        role: UPDATE_COMPANY_AMBASSADOR_ROLE,
        phone: UPDATE_COMPANY_AMBASSADOR_PHONE,
        mobile: UPDATE_COMPANY_AMBASSADOR_MOBILE,
        email: UPDATE_COMPANY_AMBASSADOR_EMAIL
      }

      const fieldName = Object.keys(values)[0]
      if (!mutations[fieldName]) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
        return
      }

      try {
        await this.$apollo.mutate({
          mutation: mutations[fieldName],
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              ...values
            }
          }
        })
        this.refetchCompany()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.features.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      }
    }
  }
}
</script>
