<template>
  <SettingsCard
    :title="$t('settings.features.social-media.title')"
    :subtitle="$t('settings.features.social-media.subtitle')"
    icon="mdi-web"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                {{ $t('settings.features.social-media.body-texts.0') }}
              </span>
            </div>
            <span class="grey--text">
              {{ $t('settings.features.social-media.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2 mb-6"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('settings.features.social-media.labels.channel') }}
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="connection of connections"
                  :key="connection.value"
                >
                  <td>
                    <v-avatar
                      left
                      size="20"
                    >
                      <v-img :src="connection.icon" />
                    </v-avatar>
                    <span class="ml-3">{{ connection.text }}</span>
                  </td>
                  <td class="text-right">
                    <v-btn
                      text
                      :disabled="isLoading"
                      :loading="isLoading"
                      color="error"
                      @click="disconnect(connection.value)"
                    >
                      {{ $t('settings.features.social-media.buttons.disconnect') }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import GET_SOCIAL_MEDIA_CONNECTIONS from '@/modules/contentCreator/creator/queries/getSocialMediaConnections.gql'
import DELETE_SOCIAL_MEDIA_CONNECTION from './queries/deleteSocialMediaConnection.gql'

import SettingsCard from '@/components/SettingsCard.vue'
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'
import { KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'

export default {
  components: {
    SettingsCard
  },
  mixins: [featureMixin],
  props: { },
  data () {
    return {
      isLoading: false,
      socialMediaConnections: {}
    }
  },
  computed: {
    connections () {
      return [
        {
          value: 'facebook',
          text: 'Facebook',
          icon: KindTypeIcon.facebookPost
        },
        {
          value: 'instagram',
          text: 'Instagram',
          icon: KindTypeIcon.instagramPost
        }
      ].filter(connection => this.socialMediaConnections[connection.value])
    }
  },
  apollo: {
    socialMediaConnections: {
      query: GET_SOCIAL_MEDIA_CONNECTIONS
    }
  },
  methods: {
    async disconnect (connection) {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: DELETE_SOCIAL_MEDIA_CONNECTION,
          variables: {
            input: connection.toUpperCase()
          },
          refetchQueries: [{ query: GET_SOCIAL_MEDIA_CONNECTIONS }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.features.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
