<template>
  <div>
    <SettingsCard
      :title="$t('settings.subscription.title')"
      :subtitle="$t('settings.subscription.subtitle')"
      icon="mdi-account-cog"
      :is-default-open="false"
    >
      <div class="pa-4">
        <!-- SUBSCRIPTION STATUS -->
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <span class="font-weight-bold">
                {{ $t('settings.subscription.body-texts.0') }}<br>
              </span>
              <span class="grey--text">
                {{ $t('settings.subscription.body-texts.1',{ readablePlatformName }) }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div class="d-flex flex-row mt-4">
              <SubscriptionState :subscription-state="subscriptionState" />

              <Permission permission="settings-subscription:change">
                <Feature :feature-slug="featureNames.CHANGE_SUBSCRIPTION">
                  <v-btn
                    color="primary"
                    text
                    class="ml-4"
                    :class="{'w-full mt-4':$vuetify.breakpoint.smAndDown}"
                    @click="handleDowngrade"
                  >
                    <v-icon>mdi-swap-horizontal</v-icon>
                    {{ $t('settings.subscription.options') }}
                  </v-btn>
                </Feature>
              </Permission>
            </div>

            <Permission permission="settings-subscription:cancel">
              <template>
                <component
                  :is="currentStep"
                  v-if="subscriptionState"
                  @handleDowngrade="handleDowngrade"
                  @actionSuccess="getContractDetails"
                />
              </template>
              <template v-slot:noPermission>
                <div class="mt-8">
                  <span class="font-weight-bold">
                    {{ $t('settings.subscription.delete-account') }}<br>
                  </span>
                  <span class="grey--text">
                    {{ $t('settings.subscription.delete-account-subtitle') }}
                  </span>
                </div>
              </template>
            </Permission>
          </v-col>
        </v-row>

        <!-- DELETE ACCOUNT -->
        <Permission permission="settings-subscription:cancel">
          <v-row v-if="showDeleteAccountSection">
            <v-col
              cols="12"
              sm="6"
              class="px-0 px-md-2"
            >
              <div>
                <span class="font-weight-bold">
                  {{ $t('settings.subscription.delete-account-full') }}<br>
                </span>
                <span class="grey--text">
                  {{ $t('settings.subscription.delete-account-full-subtitle') }}
                </span>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="px-0 px-md-2"
            >
              <DeleteAccount
                :subscription-state="subscriptionState"
                :end-date="endDate"
              />
            </v-col>
          </v-row>
        </Permission>

        <ChangeSubscription
          :dialog="isDowngradeDialog"
          :company="company"
          @close="isDowngradeDialog = false"
        />
      </div>
    </SettingsCard>
  </div>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import brandingMixing from '@/mixins/branding'
import subscriptionJsMixin from '@/modules/billwerk/subscriptionJsMixin'
import billwerkPortalMixin from '@/mixins/billwerkPortal'

import SubscriptionState from './SubscriptionState.vue'
import DeleteAccount from './DeleteAccount.vue'
import ChangeSubscription from '../ChangeSubscription'

import SubscriptionStateValue from '../enums/SubscriptionState'

import featureMixin from '@/mixins/feature'
import Permission from '@/components/Permission'
import Free from './steps/Free.vue'
import Active from './steps/Active.vue'
import Cancelled from './steps/Cancelled.vue'
import OnDelete from './steps/OnDelete.vue'

export default {
  components: {
    SettingsCard,
    SubscriptionState,
    DeleteAccount,
    ChangeSubscription,
    Permission,
    Free,
    Active,
    Cancelled
  },
  mixins: [brandingMixing, subscriptionJsMixin, billwerkPortalMixin, featureMixin],
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      revokeCancellationLoading: false,
      contractCancelLoading: false,
      isDowngradeDialog: false
    }
  },
  computed: {
    currentStep () {
      if (this.company?.deleteOn) {
        return OnDelete
      }
      return {
        [SubscriptionStateValue.FREE]: Free,
        [SubscriptionStateValue.ACTIVE]: Active,
        [SubscriptionStateValue.CANCELED]: Cancelled
      }[this.subscriptionState]
    },
    showDeleteAccountSection () {
      return [SubscriptionStateValue.CANCELED, SubscriptionStateValue.INACTIVE].includes(this.subscriptionState)
    },
    endDate () {
      return this.contractDetails?.endDate ? new Date(this.contractDetails.endDate) : null
    }
  },
  methods: {
    handleDowngrade () {
      if (this.isPlatformMarkero) {
        this.$router.push({ name: 'product-packages' })
      } else {
        this.isDowngradeDialog = true
      }
    }
  }
}
</script>
