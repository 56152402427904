<template>
  <div>
    <v-select
      outlined
      :value="this.$i18n.locale"
      :items="availableLocales"
      @change="changeLocale"
    >
      <template v-slot:item="data">
        {{ $t(`language.${data.item}`) }}
      </template>
      <template v-slot:selection="data">
        {{ $t(`language.${data.item}`) }}
      </template>
    </v-select>
  </div>
</template>

<script>
import I18n from '../i18n'
import bus, { eventNames } from '@/lib/eventBus'
import UPDATE_USER_LOCALE from '../modules/settings/profileSettings/queries/UpdateUserLocale.gql'

const { availableLocales, changeLocale } = I18n

export default {
  data () {
    return {
      availableLocales
    }
  },
  methods: {
    /**
     * Change the locale of the app and store it in the local storage
     * @param {String} locale
     */
    async changeLocale (locale) {
      this.$tracking.event('App Language', 'Changed', locale)
      changeLocale(locale)
      await this.updateUser()
    },
    async updateUser () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_LOCALE,
          variables: {
            input: {
              appLocale: this.$i18n.locale
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.language-switch.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.language-switch.error') })
      }
    }
  }
}
</script>

<style scoped>
.language-switch {
  z-index: 42;
}
</style>
