<template>
  <SettingsCard
    :title="$t('settings.features.return-call.title')"
    :subtitle="$t('settings.features.return-call.subtitle')"
    icon="mdi-phone"
    :is-default-open="false"
  >
    <div class="py-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="grey--text">
              {{ $t('settings.features.return-call.body-texts.0') }}
            </div>
            <div class="grey--text mt-4">
              {{ $t('settings.features.return-call.body-texts.1') }}
            </div>
          </div>
          <ChangeSettingsAlert
            :alert-text="$t('settings.features.return-call.body-texts.2')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="px-0 px-md-2"
        >
          <v-row>
            <v-col
              cols="12"
              md="10"
              sm="8"
            >
              <v-btn
                class="w-full"
                @click="showCreateReturnCallDialog"
              >
                <v-icon :alt="$t('buttons.return-call.cta')">
                  mdi-plus
                </v-icon>
                {{ $t('buttons.return-call.cta') }}
              </v-btn>

              <v-list dense>
                <draggable
                  v-model="returnCallTopics"
                  handle=".js-draggable"
                  @start="drag=true"
                  @end="onDragEnd"
                >
                  <transition-group
                    v-for="(item, index) in returnCallTopics"
                    :key="item.slug"
                  >
                    <v-list-item
                      v-if="!item.isHidden"
                      :key="item.slug"
                      :ripple="false"
                      class="white"
                      :class="index % 2 === 1 ? 'grey lighten-4' : ''"
                    >
                      <v-list-item-icon class="js-draggable reason-list__draggable">
                        <v-icon>mdi-drag-horizontal-variant</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.label }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action
                        :class="{'mr-10':!item.isCustom}"
                      >
                        <v-switch
                          v-model="item.isActive"
                          class="ma-0 mx-2"
                          dense
                          hide-details
                          @change="updateTopic(item)"
                        />
                      </v-list-item-action>

                      <v-list-item-action
                        v-if="item.isCustom"
                        class="ml-0"
                      >
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="() => hideTopic(item)"
                            >
                              <v-icon :alt="$t('labels.remove')">
                                mdi-trash-can-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('labels.remove') }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </transition-group>
                </draggable>
              </v-list>

              <v-btn
                class="primary mt-4 w-full"
                :disabled="isLoading || !hasChanges"
                :loading="isLoading"
                @click="saveChanges"
              >
                {{ $t('buttons.return-call.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <ReturnCallDialog
      :show="showReturnCallDialog"
      @addReason="addReturnCallReason"
      @close="showReturnCallDialog = false"
    />
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import ChangeSettingsAlert from '@/components/ChangeSettingsAlert.vue'
import ReturnCallDialog from './ReturnCallDialog.vue'
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'

export default {
  components: {
    SettingsCard,
    ChangeSettingsAlert,
    ReturnCallDialog,
    draggable: () => import(/* webpackChunkName: "import" */ 'vuedraggable')
  },
  mixins: [featureMixin],
  data: function () {
    return {
      returnCallTopics: [],
      showReturnCallDialog: false,
      hasChanges: false,
      isLoading: false
    }
  },
  created () {
    this.returnCallTopics = this.getTopics()
    bus.$on(eventNames.CHANGE_FEATURE_CONFIG, (event) => {
      if (event.slug !== featureNames.RETURNCALL_TOPICS) return
      if (event.error) bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.this.$t('alerts.settings.features.error') })
    })
  },
  methods: {
    getTopics () {
      return this.$features._featuresBySlug[featureNames.RETURNCALL_TOPICS].config.topics
    },
    showCreateReturnCallDialog () {
      this.showReturnCallDialog = true
    },
    addReturnCallReason (reason) {
      this.hasChanges = true
      const slug = reason.trim().replaceAll(' ', '_').toUpperCase()
      const existingItem = this.returnCallTopics.find((item) => item.slug === slug)
      if (existingItem) {
        this.returnCallTopics = this.returnCallTopics.map((item) =>
          item.slug === slug
            ? { ...item, isActive: true, isHidden: false }
            : item
        )
      } else {
        this.returnCallTopics.unshift(
          {
            slug,
            label: reason,
            isActive: true,
            isCustom: true
          }
        )
      }
    },
    updateTopic (topic) {
      this.hasChanges = true
    },
    hideTopic (topic) {
      this.hasChanges = true
      this.returnCallTopics = this.returnCallTopics.map((item) =>
        item.slug === topic.slug
          ? { ...item, isActive: false, isHidden: true }
          : item
      )
    },
    onDragEnd () {
      this.hasChanges = true
    },
    async saveChanges () {
      try {
        this.isLoading = true
        await this.$features.setCompanyFeature(
          {
            companyId: this.$auth.user.companyId,
            slug: featureNames.RETURNCALL_TOPICS,
            config: {
              topics: this.returnCallTopics
            }
          }
        )
        this.hasChanges = false
        this.isLoading = false
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.features.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.features.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
.reason-list__draggable {
  cursor: move;
  align-self: center;
}
</style>
