<template>
  <div>
    <SettingsCard
      :title="$t('settings.account-settings.title')"
      :subtitle="$t('settings.account-settings.subtitle')"
      icon="mdi-account-cog"
      :is-default-open="true"
    >
      <div
        class="pa-4"
      >
        <!-- PACKAGE -->
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <span class="font-weight-bold">
                {{ $t('settings.account-settings.body-texts.0') }}<br>
              </span>
              <span class="grey--text">
                {{ $t('settings.account-settings.body-texts.1', { readablePlatformName }) }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <Package
              :company="company"
              :disabled="!!pendingDowngrade?.downgradeAt"
            />
            <DowngradeInfo
              :pending-downgrade="pendingDowngrade"
              @refetch="refetchPendingDowngrade"
            />
          </v-col>
        </v-row>
      </div>
    </SettingsCard>
  </div>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import brandingMixing from '@/mixins/branding'

import Package from './Package.vue'
import DowngradeInfo from './DowngradeInfo.vue'

import GET_PENDING_DOWNGRADE from './queries/GetPendingDowngrade.gql'

export default {
  components: {
    SettingsCard,
    Package,
    DowngradeInfo
  },
  mixins: [brandingMixing],
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    refetchPendingDowngrade () {
      this.$apollo.queries.pendingDowngrade.refetch()
    }
  },
  apollo: {
    pendingDowngrade: {
      query: GET_PENDING_DOWNGRADE,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
