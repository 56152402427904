<template>
  <SettingsCard
    :title="$t('settings.payment.invoice.title')"
    :subtitle="$t('settings.payment.invoice.subtitle')"
    icon="mdi-list-box"
    :is-default-open="false"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.payment.invoice.label') }}
            </span><br>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div class="d-flex justify-end">
            <a
              :href="billingSelfServicePortalUrl"
              target="_blank"
            >
              <v-btn
                color="primary"
              >
                <v-icon
                  class="mr-2"
                  small
                >
                  mdi-file-sign
                </v-icon>
                {{ $t('settings.payment.invoice.label') }}
              </v-btn>
            </a>
          </div>
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'

export default {
  components: { SettingsCard },
  props: {
    billingSelfServicePortalDetails: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    billingSelfServicePortalUrl () {
      return this.billingSelfServicePortalDetails?.Url
    }
  }
}
</script>
