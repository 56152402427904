<template>
  <div>
    <v-btn
      color="primary"
      @click="show = true"
    >
      <v-icon>
        mdi-plus
      </v-icon>
      {{ $t('buttons.create-sender-dialog.cta') }}
    </v-btn>
    <v-dialog
      v-model="show"
      width="1000"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('settings.email.create-sender-dialog.title') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="show = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-4 text-body-1">
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <p>
                {{ $t('settings.email.create-sender-dialog.body-texts.0') }}
              </p>
              <p
                v-html="$t('settings.email.create-sender-dialog.body-texts.1')"
              />
              <div class="pt-2">
                <v-form
                  v-model="valid"
                  @submit.prevent="createSender"
                >
                  <v-text-field
                    v-model="name"
                    :label="$t('labels.name')"
                    outlined
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    v-model="fromEmail"
                    autofocus
                    :rules="[rules.email, rules.required]"
                    outlined
                    :label="$t('settings.email.create-sender-dialog.labels.from-email')"
                    required
                  />
                  <v-text-field
                    v-model="replyToEmail"
                    :rules="[rules.email]"
                    outlined
                    :label="$t('settings.email.create-sender-dialog.labels.reply-to-email')"
                  />
                </v-form>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-img src="https://static.bottimmo.com/images/assets/app-branding/email-verification-mail.png?h=600" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            @click="show = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />

          <v-btn
            color="success"
            :disabled="isLoading"
            :loading="isLoading"
            text
            @click="createSender"
          >
            {{ $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HOPPERMATION_SENDER from './queries/HoppermationSenders.gql'
import CREATE_HOPPERMATION_SENDER from './queries/CreateHoppermationSender.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
import { email, required } from '@/lib/validation'

export default {
  props: {
    companyName: { type: String, default: '' }
  },
  data () {
    return {
      rules: { email, required },
      show: false,
      isLoading: false,
      valid: false,
      name: this.companyName,
      fromEmail: null,
      replyToEmail: null
    }
  },
  watch: {
    companyName (companyName) {
      this.name = companyName
    },
    show (show) {
      if (show) {
        this.$emit('opened')
      }
    }
  },
  methods: {
    async createSender () {
      if (!this.valid) {
        return
      }
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: CREATE_HOPPERMATION_SENDER,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              name: this.name,
              fromEmail: this.fromEmail,
              replyToEmail: this.replyToEmail
            }
          },
          refetchQueries: [{
            query: HOPPERMATION_SENDER,
            variables: {
              companyId: this.$auth.user.companyId
            }
          }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.email.create-sender.success') })
        this.show = false
        this.$emit('created')
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.email.create-sender.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
