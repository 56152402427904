<template>
  <div class="w-full">
    <v-form
      ref="linksForm"
      v-model="isFormValid"
    >
      <v-row class="ma-0 pa-0 mb-2">
        <CheckTextField
          v-model="linksData.website"
          :label="$t('settings.company.website.labels.url-website')"
          placeholder="https://www.max-makler.de"
          :loading="isLoading.website"
          :rules="[rules.url, rules.required]"
          outlined
          validate-on-input
        />
      </v-row>
      <v-row class="ma-0 pa-0 mb-2">
        <CheckTextField
          v-model="linksData.about"
          :label="$t('settings.company.website.labels.url-about')"
          placeholder="https://www.max-makler.de/team"
          :loading="isLoading.about"
          :rules="[rules.url]"
          outlined
          validate-on-input
        />
      </v-row>
      <v-row class="ma-0 pa-0 mb-2">
        <CheckTextField
          v-model="linksData.imprint"
          :label="$t('settings.company.website.labels.url-imprint')"
          placeholder="https://www.max-makler.de/team"
          :loading="isLoading.imprint"
          :rules="[rules.url, rules.required]"
          outlined
          validate-on-input
        />
      </v-row>
      <v-row class="ma-0 pa-0 mb-2">
        <CheckTextField
          v-model="linksData.privacy"
          :label="$t('settings.company.website.labels.url-privacy')"
          placeholder="https://www.max-makler.de/team"
          :loading="isLoading.privacy"
          :rules="[rules.url, rules.required]"
          outlined
          validate-on-input
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { url, required } from '@/lib/validation'
import CheckTextField from '@/components/forms/CheckTextField'

export default {
  components: {
    CheckTextField
  },
  props: {
    links: {
      type: Object,
      default: () => ({})
    },
    hasDebounceTimer: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      linksData: { ...this.links },
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: {
        website: false,
        imprint: false,
        privacy: false,
        about: false
      },
      isFormValid: false,
      isFieldValid: {
        website: false,
        imprint: false,
        privacy: false,
        about: false
      }
    }
  },

  watch: {
    links () {
      this.linksData = { ...this.links }
    },
    'linksData.website' () {
      this.update('website', this.linksData.website)
    },
    'linksData.about' () {
      this.update('about', this.linksData.about)
    },
    'linksData.imprint' () {
      this.update('imprint', this.linksData.imprint)
    },
    'linksData.privacy' () {
      this.update('privacy', this.linksData.privacy)
    }
  },
  created () {
    this.rules = { url, required }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },

  methods: {
    async update (name, value) {
      if (this.links[name] === value) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading[name] = true
        this.spinnerTimeout = setTimeout(async () => {
          const isFormValid = await this.$refs.linksForm.validate()
          if (isFormValid) {
            this.$emit('updateSettings', { ...this.linksData })
          }
          this.isLoading[name] = false
        }, 1000)
      }, 2000)
    }
  }
}
</script>
